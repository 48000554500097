// Generic imports
import React, { useEffect } from "react"

// Other components imports
import CreateClass from "components/CreateClass"
import Layout from "components/layout"
import SEO from "components/seo"

// Redux imports

// Static imports

// Utils imports
import { trackCreateClass } from "trackers/amplitude"

const CreateClassPage = () => {
  useEffect(() => {
    trackCreateClass()
  }, [])

  return (
    <Layout>
      <SEO title="Create class" />
      <CreateClass />
    </Layout>
  )
}

export default CreateClassPage
